import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import { useParams } from 'react-router-dom';
import { Container, Grid, InputLabel, FormControl, RadioGroup, FormControlLabel, Radio, Button, FormGroup, Checkbox, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { getCollectionsComplete, getDate, top, updateData } from '../../../assets/js/functions';
import Logo from '../../../assets/images/login/logo.png';

export default function SatisfaccionOperacionesConsultor() {
	const [termino, setTermino] = useState(false);
	const [datos, setDatos] = useState({ preguntaDieciseis: '', preguntaDiecisiete: '' });
	const [p7, setP7] = useState({ opcion1: '', opcion2: '', opcion3: '', opcion4: '', opcion5: '', opcion6: '', opcion7: '', opcion8: '', otro: '' });
	const [otrop7, setOtrop7] = useState(false);
	const [opcion1, setOpcion1] = useState(false);
	const [opcion2, setOpcion2] = useState(false);
	const [opcion3, setOpcion3] = useState(false);
	const [opcion4, setOpcion4] = useState(false);
	const [opcion5, setOpcion5] = useState(false);
	const [opcion6, setOpcion6] = useState(false);
	const [opcion7, setOpcion7] = useState(false);
	const [opcion8, setOpcion8] = useState(false);
	const [opcion9, setOpcion9] = useState(false);
	const [opcion10, setOpcion10] = useState(false);
	const [opcion11, setOpcion11] = useState(false);
	const [opcion12, setOpcion12] = useState(false);
	const [opcion13, setOpcion13] = useState(false);
	const [opcion14, setOpcion14] = useState(false);
	const [opcion15, setOpcion15] = useState(false);
	const [opcion16, setOpcion16] = useState(false);
	const [otrop10, setOtrop10] = useState(false);
	const [p10, setP10] = useState({ opcion1: '', opcion2: '', opcion3: '', opcion4: '', opcion5: '', opcion6: '', opcion7: '', opcion8: '', otro: '' });
	const [seccionVisible, setSeccionVisible] = useState(1);
	const params = useParams();

	useEffect(() => {
		top();
		validar();
	}, []);

	const validar = async () => {
		const data = await getCollectionsComplete(`pruebas/${params.id}`);
		const termino = localStorage.getItem(`${params.id}`);
		if (termino === '1') {
			setTermino(true);
		}

		if (data.status === 'Sin abrir') {
			updateData('pruebas', params.id, { status: 'Abierto' });
		}
	};

	const registrar = () => {
		const idUniq = uniqid();
		const json = {
			...datos,
			preguntaOnce: p7,
			preguntaCatorce: p10,
			fechaRegistroCliente: getDate(),
		};
		if (
			!datos.preguntaCinco ||
			!datos.preguntaSeis ||
			!datos.preguntaSiete ||
			!datos.preguntaOcho ||
			!datos.preguntaNueve ||
			!datos.preguntaDiez ||
			!datos.preguntaOnce ||
			!datos.preguntaDoce ||
			!datos.preguntaTrece ||
			!datos.preguntaCatorce ||
			!datos.preguntaQuince ||
			!datos.preguntaDieciseis
		) {
			Swal.fire({
				icon: 'warning',
				title: 'Cuidado',
				text: 'Tienes preguntas sin contestar',
				confirmButtonColor: '#e10613',
			});
			return null;
		} else {
			updateData(`pruebas`, params.id, { status: 'Terminado' });
			localStorage.setItem(`${params.id}`, '1');
			updateData(`pruebas/${params.id}/respuestas`, idUniq, json).then(() => {
				Swal.fire({
					icon: 'success',
					title: 'Bien Hecho',
					text: 'Datos guardados exitosamente...',
					confirmButtonColor: '#e10613',
				}).then((result) => {
					if (result.isConfirmed) {
						setTermino(true);
					}
				});
			});
		}
	};

	const cambiarSeccion = () => {
		top();
		setSeccionVisible(seccionVisible + 1);
	};

	const setPregunta7 = (opcion, valor) => {
		let json = p7;

		switch (opcion) {
			case 1:
				if (valor) {
					setOpcion1(true);
					json['opcion1'] = 'Comunicación abierta';
				} else {
					setOpcion1(false);
					json['opcion1'] = '';
				}

				break;
			case 2:
				if (valor) {
					setOpcion2(true);
					json['opcion2'] = 'Asesoramiento durante todo el proyecto';
				} else {
					setOpcion2(false);
					json['opcion2'] = '';
				}

				break;
			case 3:
				if (valor) {
					setOpcion3(true);
					json[opcion3] = 'Trato personalizado';
				} else {
					setOpcion3(false);
					json[opcion3] = '';
				}

				break;
			case 4:
				if (valor) {
					setOpcion4(true);
					json['opcion4'] = 'Flexibilidad y adaptación al cambio';
				} else {
					setOpcion4(false);
					json['opcion4'] = '';
				}

				break;
			case 5:
				if (valor) {
					setOpcion5(true);
					json['opcion5'] = 'Escucha activa y apertura a la retroalimentación';
				} else {
					setOpcion5(false);
					json['opcion5'] = '';
				}

				break;
			case 6:
				if (valor) {
					setOpcion6(true);
					json['opcion6'] = 'Amabilidad y cordialidad';
				} else {
					setOpcion6(false);
					json['opcion6'] = '';
				}

				break;
			case 7:
				if (valor) {
					setOpcion7(true);
					json['opcion7'] = 'Agilidad para ofrecer alternativas y soluciones';
				} else {
					setOpcion7(false);
					json['opcion7'] = '';
				}

				break;
			case 8:
				if (valor) {
					setOpcion8(true);
					setOtrop7(true);
					json['opcion8'] = 'Otros (especifique)';
				} else {
					setOpcion8(false);
					setOtrop7(false);
					json['opcion8'] = '';
				}

				break;

			default:
				break;
		}
		setP7(json);
		setDatos({ ...datos, preguntaOnce: json });
	};

	const setPregunta10 = (opcion, valor) => {
		let json = p10;

		switch (opcion) {
			case 1:
				if (valor) {
					setOpcion9(true);
					json['opcion1'] = 'Aceptables';
				} else {
					setOpcion9(false);
					json['opcion1'] = '';
				}

				break;
			case 2:
				if (valor) {
					setOpcion10(true);
					json['opcion2'] = 'Atractivos';
				} else {
					setOpcion10(false);
					json['opcion2'] = '';
				}

				break;
			case 3:
				if (valor) {
					setOpcion11(true);
					json['opcion3'] = 'Impecables';
				} else {
					setOpcion11(false);
					json['opcion3'] = '';
				}

				break;
			case 4:
				if (valor) {
					setOpcion12(true);
					json['opcion4'] = 'Innovadores/Diferentes';
				} else {
					setOpcion12(false);
					json['opcion4'] = '';
				}

				break;
			case 5:
				if (valor) {
					setOpcion13(true);
					json['opcion5'] = 'Creativos';
				} else {
					setOpcion13(false);
					json['opcion5'] = '';
				}

				break;
			case 6:
				if (valor) {
					setOpcion14(true);
					json['opcion6'] = 'Funcionales';
				} else {
					setOpcion14(false);
					json['opcion6'] = '';
				}

				break;
			case 7:
				if (valor) {
					setOpcion15(true);
					json['opcion7'] = 'Centrados en el usuario';
				} else {
					setOpcion15(false);
					json['opcion7'] = '';
				}

				break;
			case 8:
				if (valor) {
					setOpcion16(true);
					setOtrop10(true);
					json['opcion8'] = 'Otros (especifique)';
				} else {
					setOpcion16(false);
					setOtrop10(false);
					json['opcion8'] = '';
				}

				break;

			default:
				break;
		}
		setP10(json);
		setDatos({ ...datos, preguntaCatorce: json });
	};

	let backgroundStyles = '';
	backgroundStyles += `#root::after {background-color: #fff }`;

	return (
		<>
			<style>{backgroundStyles}</style>
			<div className='header-free'>
				<img src={Logo} alt='logo' />
			</div>
			<Container maxWidth='lg' style={{ marginBottom: '40px' }}>
				{termino === true && <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>Gracias por contestar la encuesta</h2>}
				{termino === false && (
					<Grid container justifyContent='center' alignItems='center' spacing={4}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<div className='titulo-encuesta'>Encuesta de satisfacción operativa</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<>
								<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial' }}>
									Por favor, califique las siguientes afirmaciones según lo que aprecia de nuestro servicio, experiencia y calidad. Considere 1 la calificación más baja, y 5 la más alta.
								</InputLabel>
							</>
						</Grid>
						{seccionVisible === 1 && (
							<>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											1.El equipo Spira cumplió con la oferta presentada en la propuesta comercial
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaCinco: e.target.value })} value={datos.preguntaCinco}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											2.El equipo Spira mostró experiencia y dominio técnico requerido para cumplir la oferta de valor
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaSeis: e.target.value })} value={datos.preguntaSeis}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											3.Las propuestas presentadas responden a la estrategia, retos y necesidades de su área /organización
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaSiete: e.target.value })} value={datos.preguntaSiete}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											4.Las propuestas presentadas responden a las necesidades y preferencias del público objetivo
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaOcho: e.target.value })} value={datos.preguntaOcho}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											5.El equipo Spira mantuvo comunicación y acompañamiento durante todo el proyecto
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaNueve: e.target.value })} value={datos.preguntaNueve}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
							</>
						)}
						{/* seccionVisible 2 */}
						{seccionVisible === 2 && (
							<>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											6.El equipo Spira mostró flexibilidad, adaptación y brindó alternativas ante el cambio y/o nuevos requerimientos
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaDiez: e.target.value })} value={datos.preguntaDiez}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											7.¿Qué elementos reconoce en el SERVICIO brindado por el equipo SPIRA? Seleccione 1 o varios
										</InputLabel>
										<FormControl>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion1}
															onChange={(e) => setPregunta7(1, e.target.checked)}
														/>
													}
													label='Comunicación abierta'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion2}
															onChange={(e) => setPregunta7(2, e.target.checked)}
														/>
													}
													label='Asesoramiento durante todo el proyecto'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion3}
															onChange={(e) => setPregunta7(3, e.target.checked)}
														/>
													}
													label='Trato personalizado'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion4}
															onChange={(e) => setPregunta7(4, e.target.checked)}
														/>
													}
													label='Flexibilidad y adaptación al cambio'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion5}
															onChange={(e) => setPregunta7(5, e.target.checked)}
														/>
													}
													label='Escucha activa y apertura a la retroalimentación
'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion6}
															onChange={(e) => setPregunta7(6, e.target.checked)}
														/>
													}
													label='Amabilidad y cordialidad
'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion7}
															onChange={(e) => setPregunta7(7, e.target.checked)}
														/>
													}
													label='Agilidad para ofrecer alternativas y soluciones
'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion8}
															onChange={(e) => setPregunta7(8, e.target.checked)}
														/>
													}
													label='Otros (especifique)
'
												/>
											</FormGroup>
										</FormControl>
										{otrop7 === true && <TextField fullWidth value={p7.otro} variant='outlined' onChange={(e) => setP7({ ...p7, otro: e.target.value })} />}
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											8.Fue clara la secuencia de actividades e hitos para desarrollar el proyecto
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaDoce: e.target.value })} value={datos.preguntaDoce}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											9.Se comunicaron los avances y riesgos del proyecto con oportunidad
										</InputLabel>
										<FormControl>
											<RadioGroup row aria-labelledby='demo-row-radio-buttons-group-label' name='row-radio-buttons-group' onChange={(e) => setDatos({ ...datos, preguntaTrece: e.target.value })} value={datos.preguntaTrece}>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											10.¿Cómo describe las ejecuciones/entregables desarrollados por el equipo Spira? Seleccione 1 o varios
										</InputLabel>
										<FormControl>
											<FormGroup>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion9}
															onChange={(e) => setPregunta10(1, e.target.checked)}
														/>
													}
													label='Aceptables'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion10}
															onChange={(e) => setPregunta10(2, e.target.checked)}
														/>
													}
													label='Atractivos'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion11}
															onChange={(e) => setPregunta10(3, e.target.checked)}
														/>
													}
													label='Impecables'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion12}
															onChange={(e) => setPregunta10(4, e.target.checked)}
														/>
													}
													label='Innovadores/Diferentes'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion13}
															onChange={(e) => setPregunta10(5, e.target.checked)}
														/>
													}
													label='Creativos'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion14}
															onChange={(e) => setPregunta10(6, e.target.checked)}
														/>
													}
													label='Funcionales'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion15}
															onChange={(e) => setPregunta10(7, e.target.checked)}
														/>
													}
													label='Centrados en el usuario'
												/>
												<FormControlLabel
													control={
														<Checkbox
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
															checked={opcion16}
															onChange={(e) => setPregunta10(8, e.target.checked)}
														/>
													}
													label='Otros (especifique)'
												/>
											</FormGroup>
										</FormControl>
										{otrop10 === true && <TextField fullWidth value={p10.otro} variant='outlined' onChange={(e) => setP10({ ...p10, otro: e.target.value })} />}
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											11.¿Qué tan satisfecho está con el trabajo del equipo SPIRA y el resultado de su proyecto?
										</InputLabel>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby='demo-row-radio-buttons-group-label'
												name='row-radio-buttons-group'
												onChange={(e) =>
													setDatos({
														...datos,
														preguntaQuince: e.target.value,
													})
												}
												value={datos.preguntaQuince}
											>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											12.En una escala de 1 a 10, ¿En qué medida estaría dispuesto a recomendar SPIRA a sus conocidos?
										</InputLabel>
										<FormControl>
											<RadioGroup
												row
												aria-labelledby='demo-row-radio-buttons-group-label'
												name='row-radio-buttons-group'
												onChange={(e) =>
													setDatos({
														...datos,
														preguntaDieciseis: e.target.value,
													})
												}
												value={datos.preguntaDieciseis}
											>
												<FormControlLabel
													value='1'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='1'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='2'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='2'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='3'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='3'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='4'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',

																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='4'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='5'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='5'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='6'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='6'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='7'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='7'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='8'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='8'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='9'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='9'
													labelPlacement='bottom'
												/>
												<FormControlLabel
													value='10'
													control={
														<Radio
															size='large'
															sx={{
																color: '#5a5a5a',
																'&.Mui-checked': {
																	color: '#e10613',
																},
															}}
														/>
													}
													label='10'
													labelPlacement='bottom'
												/>
											</RadioGroup>
										</FormControl>
									</>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<>
										<InputLabel htmlFor='input-with-icon-adornment' style={{ fontSize: '16px', whiteSpace: 'initial', marginBottom: '10px' }}>
											13.¿Desea expresar algún RECONOCIMIENTO, SUGERENCIA Y/O RETROALIMENTACIÓN? Por favor, escríbala a continuación.
										</InputLabel>
										<TextField multiline rows={6} fullWidth value={datos.preguntaDiecisiete} variant='outlined' onChange={(e) => setDatos({ ...datos, preguntaDiecisiete: e.target.value })} />
									</>
								</Grid>
							</>
						)}

						{/* Botones */}
						<Grid item xs={12} sm={12} md={12} lg={12}>
							{seccionVisible > 1 && (
								<Button className='blanco' variant='contained' style={{ background: '#e10613' }} onClick={() => setSeccionVisible(seccionVisible - 1)}>
									Regresar
								</Button>
							)}
							&nbsp;
							<Button className='blanco' variant='contained' style={{ background: '#e10613' }} onClick={() => (seccionVisible !== 2 ? cambiarSeccion() : registrar())}>
								{seccionVisible !== 2 ? 'Siguiente' : 'Enviar'}
							</Button>
						</Grid>
					</Grid>
				)}
			</Container>
		</>
	);
}
